






































import {Component, Prop, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import clickInside from '@/utils/clickInside';
import NoContent from '@/components/NoContent.vue';
import LoaderBottom from '@/components/LoaderBottom.vue';
import FollowerInterface from '@/types/FollowerInterface';
import debounce from '@/utils/debounce';

@Component({
  components: {NoContent, Loader, LoaderBottom},
  directives: {
    clickInside,
  },
})
export default class Followers extends Vue {
  @Prop({type: String, required: true}) readonly id!: string;

  isFollowersLoading = true;
  isFollowingLoading = true;
  followers = [] as FollowerInterface[];
  following = [] as FollowerInterface[];
  limit = 20;
  followersPage = 1;
  followersTotal = 0;
  followersComplete = false;
  followingPage = 1;
  followingTotal = 0;
  followingComplete = false;
  debounceHandler = () => {
    /* Will be replaced */
  };

  get isFollowersRoute(): boolean {
    return this.$route.name === 'followers';
  }
  get isOwnProfile(): boolean {
    return this.$route.params.profile === vxm.user.data.username;
  }
  get noFollowersMessage(): string {
    return this.isOwnProfile
      ? `No one follows you now.\nTry checking later!`
      : `No one follows this user.\nWant to be the first one?`;
  }
  get noFollowingMessage(): string {
    return this.isOwnProfile
      ? `You don't follow anyone.\nGo ahead and find somebody!`
      : `This user doesn't follow anyone.\nTry checking later!`;
  }

  created() {
    this.debounceHandler = debounce(this.onScroll, 300);
    window.addEventListener('scroll', this.debounceHandler, false);
    this.getLists();
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.debounceHandler, false);
  }

  onScroll(): void {
    if (this.isFollowersRoute) {
      if (this.followersComplete) {
        return;
      }
      if (this.isPageBottom()) {
        this.getFollowers();
      }
    } else {
      if (this.followingComplete) {
        return;
      }
      if (this.isPageBottom()) {
        this.getFollowing();
      }
    }
  }
  getLists(): void {
    if (this.$route.name === 'followers') {
      this.getFollowers();
      this.getFollowing();
    } else {
      this.getFollowing();
      this.getFollowers();
    }
  }
  changeFollower(id: string, index: number): void {
    if (index < 0 || index > this.followers.length - 1 || !id) {
      return;
    }
    this.isFollowersLoading = true;
    vxm.user
      .follow({userId: id})
      .then(() => {
        const user = this.followers[index];
        if (user.isPrivate && !user.isFollowed) {
          user.isRequested = true;
        } else if (this.isOwnProfile) {
          if (user.isFollowed) {
            this.removeFollowing(id);
          } else {
            this.addFollowing(user);
          }
        }
        user.isFollowed = !user.isFollowed;
        this.followers.splice(index, 1, user);
      })
      .finally(() => {
        this.isFollowersLoading = false;
      });
  }
  changeFollowing(id: string, index: number): void {
    if (index < 0 || index > this.following.length - 1 || !id) {
      return;
    }
    this.isFollowingLoading = true;
    vxm.user
      .follow({userId: id})
      .then(() => {
        const user = this.following[index];
        if (this.isOwnProfile) {
          this.changeFollowerState(id);
          this.$emit('followingCountChanged', !user.isFollowed ? 1 : -1);
        }
        user.isFollowed = !user.isFollowed;
        this.following.splice(index, 1, user);
      })
      .finally(() => {
        this.isFollowingLoading = false;
      });
  }
  changeFollowerState(id: string): void {
    if (!id) {
      return;
    }
    const index = this.followers.findIndex((user) => user._id === id);
    if (index === -1) {
      return;
    }
    const user = this.followers[index];
    if (user.isPrivate && !user.isFollowed) {
      user.isRequested = true;
    } else {
      user.isFollowed = !user.isFollowed;
    }
    this.followers.splice(index, 1, user);
  }
  removeFollowing(id: string): void {
    if (!id) {
      return;
    }
    const index = this.following.findIndex((user) => user._id === id);
    if (index !== -1) {
      this.following.splice(index, 1);
      this.$emit('followingCountChanged', -1);
    }
  }
  addFollowing(user: FollowerInterface): void {
    if (user && user._id) {
      this.following.push(user);
      this.$emit('followingCountChanged', 1);
    }
  }
  getFollowers() {
    vxm.user
      .getFollowers({
        id: this.id,
        page: this.followersPage,
        limit: this.limit,
      })
      .then(async (res: {data: {items: []; total: number}}) => {
        this.followers.push(...res.data.items);
        this.followersTotal = res.data.total;
        if (this.limit * this.followersPage >= this.followersTotal) {
          this.followersComplete = true;
        }
        this.followersPage += 1;
        await this.$nextTick();
        if (this.isPageBottom() && !this.followersComplete) {
          this.debounceHandler();
        }
      })
      .finally(() => {
        this.isFollowersLoading = false;
      });
  }
  getFollowing() {
    vxm.user
      .getFollowing({
        id: this.id,
        page: this.followingPage,
        limit: this.limit,
      })
      .then(async (res: {data: {items: []; total: number}}) => {
        this.following.push(...res.data.items);
        this.followingTotal = res.data.total;
        if (this.limit * this.followingPage >= this.followingTotal) {
          this.followingComplete = true;
        }
        this.followingPage += 1;
        await this.$nextTick();
        if (this.isPageBottom() && !this.followingComplete) {
          this.debounceHandler();
        }
      })
      .finally(() => {
        this.isFollowingLoading = false;
      });
  }
  onFollowersClick(): void {
    if (!this.isFollowersRoute) {
      this.$router.push({name: 'followers'});
    }
  }
  onFollowingClick(): void {
    if (this.isFollowersRoute) {
      this.$router.push({name: 'following'});
    }
  }
  closeModal(): void {
    this.$router.push({name: 'profile', params: {profile: this.$route.params.profile}});
  }
  getUserAvatar(user: {avatar: {link: {medium: string | null; origin: string | null}} | null}): string {
    return user.avatar && user.avatar.link
      ? user.avatar.link.medium || user.avatar.link.origin || require('@/assets/icons/avatar-default.svg')
      : require('@/assets/icons/avatar-default.svg');
  }
  notOwnProfile(id: string): boolean {
    return vxm.user.data._id !== id;
  }
  isPageBottom(): boolean {
    const scrollable = document.getElementById('followers-scrollable');
    if (!scrollable) {
      return false;
    }
    return scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight - this.getBottomLoaderHeight();
  }
  getBottomLoaderHeight(): number {
    const bottomLoader = document.getElementById('bottom-loader');
    return bottomLoader ? bottomLoader.clientHeight : 0;
  }
}
